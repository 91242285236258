import { lazy, Suspense } from 'react'

import { BrowserRouter as Router } from 'react-router-dom'

import MainRoutes from 'Routes'

const Header = lazy(() => import('./components/Header'))
const Footer = lazy(() => import('./components/Footer'))

function App() {
  return (
    <Router>
      <Suspense>
        <Header />
        <MainRoutes />
        <Footer />
      </Suspense>
    </Router>
  )
}

export default App
